import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`retail`}</p>
    <p>{`ERPNext for Retail
ERPNext can help you manage your shop or multiple shops with a web based POS with integrated inventory and accounting.`}</p>
    <p>{`Try ERPNext free for 14 days`}</p>
    <p>{`Point of Sale
The ERPNext POS helps you manage your store checkout easily with integrated billing and inventory.
Inventory
ERPNext stock module will help you manage inventory and procurement across warehouses.
Billing and Payments
Track all things financial, including billings and payments with the ERPNext accounts module.
Items and Variants
ERPNext Item management helps you create and manage your manufacturing items and its variants.
Batches and Serial Numbers
ERPNext has built-in support for batched and serialized inventory that will help you in traceability.
HR and Payroll
Manage your employee data, payroll, leave and attendance, expense claims, all within your ERPNext system.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      